
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/s/[username]/[productId]/payment",
      function () {
        return require("private-next-pages/s/[username]/[productId]/payment/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/s/[username]/[productId]/payment"])
      });
    }
  