import React from 'react'

import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

interface Props {
  header: string
  footer: string
}

export const InvoicePaid: React.FC<Props> = ({ header, footer }) => (
  <div className='container'>
    <div dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(header) || '' }} />
    <div className='product-cont'>
      <div className='text-center padding-100px font-40px'>
        <h3 />
        {I18n.t('react.shop.payment.invoice_already_paid')}
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(footer) || '' }} />
  </div>
)
